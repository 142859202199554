<template>
  <div class="emailHome">
    <!-- 顶部操作栏组件 -->
    <TopOperation
      ref="TopOperation"
      :EmailType="type"
      @checkall="checkall"
      @refresh="refresh"
      @tabemaillist="tabemaillist"
      @deleteemail="deleteemail"
      @changeisread="changeisread"
      @seachkeyword="seachkeyword"
    ></TopOperation>
    <!-- 邮件列表组件 -->
    <emailsList
      :EmailType="type"
      ref="emailsList"
      @ReverseSelection="ReverseSelection"
      @clearSignas="clearSignas"
    ></emailsList>
  </div>
</template>

<script>
import emailsList from "@/views/Email/emailsList";
import TopOperation from "@/views/Email/TopOperation";
export default {
  data(){
    return{
      type:this.$route.query.type
    }
  },
  components: {
    emailsList,
    TopOperation,
  },
  methods: {
    //全选----组件方法
    checkall(params) {
      this.$refs.emailsList.checkListAll(params);
    },
    //反选----组件方法
    ReverseSelection(params) {
      this.$refs.TopOperation.ReverseSelectionList(params);
    },
    //刷新----组件方法
    refresh() {
      this.$refs.emailsList.refreshFun();
    },
    //切换邮件列表----组件方法
    tabemaillist(params) {
      this.$refs.emailsList.tabEmailList(params);
    },
    //删除邮件----组件方法
    deleteemail() {
      this.$refs.emailsList.deleteDialog();
    },
    //标记邮件----组件方法
    changeisread(params) {
      this.$refs.emailsList.MarkMail(params);
    },
    //清空标记选项列表----组件方法
    clearSignas() {
      this.$refs.TopOperation.clearSign();
    },
    //搜索邮件----组件方法
    seachkeyword(params) {
      this.$refs.emailsList.searchMail(params);
    },
  },
};
</script>

<style lang="scss" scoped>
.emailHome {
  padding: 15px 0 0 0;
  width: 100%;
}
</style>