<template>
  <!-- 邮件列表组件 -->
  <div>
    <div
      class="listBox"
      v-loading="isloading"
      :style="{ height: inboxListheight }"
      v-infinite-scroll="scrollLoad"
      :infinite-scroll-disabled="disabled"
    >
      <ul v-if="inboxlist.length != 0">
        <li
          v-for="(item, index) in inboxlist"
          :key="item.id"
          :style="{ fontWeight: item.isread  === '0' && EmailType== '1' ? 'bold' : 'normal' }"
        >
        <!-- 左侧复选框 -->
          <div style="padding-left: 15px">
            <span
              class="check"
              :style="{
                backgroundColor: item.ischeck == true ? '#006DCC' : '#fff',
                marginRight:
                  EmailType == '0' || EmailType == 'temp' ? '10px' : '0',
              }"
              @click="changecheck(item.id)"
            >
              <i class="el-icon-check" v-show="item.ischeck == true"></i>
            </span>
          </div>

          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            v-if="EmailType == '0'"
          >
            <div slot="content" style="text-align: center">
              <span style="display: block">
                <!-- 打开 -->
                {{ $t("label.permission.objectpermission.open") }}
                {{ item.opentimes }}
                <!-- 次 -->
                {{ $t("label.salescloud.ci") }}
              </span>
              <span style="display: block" v-if="item.opentimes != 0">{{
                item.lastopendate
              }}</span>
            </div>
            <div class="istrack" v-if="item.istrackopen != '0'">
              <svg class="icon" aria-hidden="true">
                <use
                  :href="item.opentimes != '0' ? changeimgurl : imgurl"
                ></use>
              </svg>
            </div>
          </el-tooltip>
          <!-- 收、发件箱 -->
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
            v-if="EmailType !='temp'"
            @click="godetail(item.id, index)"
          >
            <div style="display: flex; align-items: center; width: 240px">
              <span
                class="isread"
                v-if="EmailType == '1'||EmailType== '5'"
                :style="{
                  backgroundColor:
                    item.isread != '1' && item.isread != ''
                      ? '#c12218'
                      : '#fff',
                }"
              ></span>
              <!-- 收件箱显示发件人 -->
              <h3
                v-if="EmailType == '1'||EmailType== '5'"
                style="
                  width: 200px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  color: #333333;
                "
                :style="{
                  fontWeight: item.isread === '0' ? 'bold' : 'normal',
                  marginLeft: EmailType == '0' ? '10px' : '0',
                }"
              >
                {{ item.fromaddress }}
              </h3>
              <!-- 发件箱显示收件人 -->
              <h3
                v-if="EmailType == '0'"
                style="
                  width: 200px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  color: #333333;
                "
                :style="{
                  fontWeight: item.isread === '0' ? 'bold' : 'normal',
                  marginLeft: EmailType == '0' ? '10px' : '0',
                }"
              >
                {{ item.toaddress }}
              </h3>
              <i
                class="el-icon-paperclip"
                style="font-size: 18px"
                v-if="item.isattachment == '1' ||EmailType== '5'"
              ></i>
            </div>
            <div
              style="
                width: 450px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                color: #333333;
              "
            >
              <span v-html="item.name" v-if="EmailType == '1'||EmailType== '5'"></span>
              <span v-html="item.name" v-if="EmailType == '0'" style="font-weight: bold;"></span>
              <span>-&emsp;</span>
              <span v-html="item.htmlbody"></span>
            </div>
            <span class="relate">
              {{ item.relateidccname ? item.relateidccname : "" }}
            </span>
            <span style="width: 124px">
              {{ item._id.time }}
            </span>
          </div>


          <!-- 草稿箱 -->
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            "
            v-if="EmailType == 'temp'"
            @click="godetail(item.id)"
          >
            <div style="display: flex; align-items: center; width:240px">
              <h3
                style="
                  width: 200px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  color: #333333;
                  margin-left:10px
                "
              >
                {{ item.name }}
              </h3>
              <i
                class="el-icon-paperclip"
                style="font-size: 18px"
                v-if="item.isattachment == '1'"
              ></i>
            </div>
            <div style="">
              <h3
                v-html="item.htmlbody"
                style="
                  width: 450px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-size: 14px;
                  color: #333333;
                "
              >
                {{ item.htmlbody }}
              </h3>
            </div>
            <span class="relate">
              {{ item.relateidccname ? item.relateidccname : "" }}
            </span>
            <span style="width:124px">
              {{ item._id.time }}
            </span>
          </div>
        </li>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          "
        >
          <p v-if="scrollLoading" style="margin: 10px 0 0 0">Loading...</p>
          <!-- 没有更多数据了 -->
          <p v-else-if="noMore" style="margin: 10px 0 0 0">
            {{ $t("vue_label_commonobjects_detail_more_data") }}
          </p>
        </div>
      </ul>
      <!-- 收件箱空数据页 -->
      <div class="nothing" v-if="inboxlist.length == 0 && EmailType == '1'">
        <div class="nothingicon">
           <svg class="icon noDrafts" aria-hidden="true">
                <use href="#icon-nonews"></use>
                   </svg>
          <!-- 暂时没有收到邮件哦～ -->
          <h5>{{ $t("vue_label_email_havent_received_the_email_yet") }}</h5>
        </div>
      </div>
      <!-- 发件箱空数据页 -->
      <div class="nothing" v-if="inboxlist.length == 0 && EmailType == '0'">
        <div class="nothingicon">
          <svg class="icon noDrafts" aria-hidden="true">
                    <use href="#icon-nonews"></use>
                   </svg>
          <!-- 您CRM中的潜在客户、联系人发来的邮件，以及收件人中包含他们的邮件，都会被归类到这里 -->
          <h5>{{$t('vue_label_email_emails_from_potential')}}</h5>
        </div>
      </div>
      <!-- 草稿箱空数据页 -->
       <div class="nothing" v-if="inboxlist.length == 0 && EmailType == 'temp'">
        <div class="nothingicon">
          <!-- 暂时没有草稿哦 vue_label_email_havent_received_the_email_yet-->
          <svg class="icon noDrafts" aria-hidden="true">
                <use href="#icon-nonews"></use>
                   </svg>
          <h5>{{$t('vue_label_email_havent_received_the_email_yet')}}</h5>
        </div>
      </div>


    </div>
    <!-- 删除弹窗 -->
    <div>
      <!-- 提示 -->
      <el-dialog
        :title="$t('label.prompt')"
        :visible.sync="deletestatus"
        width="30%"
      >
        <span>
          <!-- 确认要删除吗 -->
          {{ $t("label.temp.del.conf") }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deletestatus = false">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button type="primary" @click="deletefun" :loading="submitloading">
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import * as Emailobject from "./api.js";
import getTimeStringAutoShort from "@/utils/changeTimeMultitemporal.js";
export default {
  data() {
    return {
      inboxListheight: `${document.body.offsetHeight - 180}px`,
      inboxlist: [],
      deletestatus: false,
      seachtype: "all",
      scrollnum: 1,
      ischeck: false,
      offtime: null,
      submitloading: false,
      isloading: true,
      scrollLoading: false,
      listlength: 0,
      imgurl: "#icon-show",
      changeimgurl: "#icon-show_blue",
    };
  },
  props: {
    //1:收件箱 0:发件箱 temp:草稿箱
    EmailType: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getinboxlist(
      this.EmailType,
      this.seachtype,
      "25",
      this.scrollnum,
      this.offtime,
      ""
    );
  },
  computed: {
    //限制滚动加载次数(节流)
    disabled() {
      return this.scrollLoading || this.noMore;
    },
    noMore() {
      return this.listlength < 25;
    },
  },
  methods: {
    //获取邮件列表
    getinboxlist(incoming, condition, shownum, showpage, offtime, searchWord) {
      this.isloading = true;
      Emailobject.inbox({
        incoming: incoming,
        condition: condition,
        shownum: shownum,
        showpage: showpage,
        offtime: offtime,
        searchWord: searchWord,
      })
        .then((res) => {
          this.$Bus.$emit("get-email-noreadnum");
          this.offtime = res.data.offtime;
          let num = 1;
          res.data.data.forEach((item) => {
            item._id.time = getTimeStringAutoShort(
              item._id.time,
              true,
              this.$store.state.userInfoObj.timeZone
            );
            this.$set(item, "ischeck", this.ischeck);
            this.$set(item, "num", num++);
            this.inboxlist.push(item);
          });
          this.listlength = res.data.data.length;
          this.isloading = false;
          this.scrollLoading = false;
        })
        .catch(() => {});
    },
    //滚动加载
    scrollLoad() {
      this.scrollLoading = true;
      this.scrollnum += 1;
      let scrollstr = "" + this.scrollnum;
      this.getinboxlist(
        this.EmailType,
        this.seachtype,
        "25",
        scrollstr,
        this.offtime,
        ""
      );
    },
    //搜索邮件
    searchMail(params) {
      this.inboxlist = [];
      this.scrollnum = 1;
      this.offtime = null;
      this.getinboxlist(
        this.EmailType,
        this.seachtype,
        "25",
        this.scrollnum,
        this.offtime,
        params
      );
    },
    //标记邮件为已读或未读
    MarkMail(params) {
      let istrue = this.inboxlist.every((item) => {
        return item.ischeck == false;
      });
      if (istrue == true) {
        this.$emit("clearSignas");
        // 请至少选择一封邮件;
        this.$message({
          showClose: true,
          message: this.$i18n.t(
            "vue_label_email_Please_select_at_least_one_email"
          ),
          type: "warning",
        });
      } else {
        this.isloading = true;
        let idarr = [];
        this.inboxlist.forEach((item) => {
          if (item.ischeck == true) {
            idarr.push(item.id);
          }
        });
        let ids = idarr.join(",");
        Emailobject.changeread({
          ids: ids,
          isread: params,
        })
          .then((res) => {
            if (res.result == true) {
              this.isloading = false;
              this.inboxlist.forEach((item) => {
                if (item.ischeck == true) {
                  item.isread = params;
                }
              });
            }
            this.$Bus.$emit("get-email-noreadnum");
          })
          .catch(() => {});
      }
    },
    //删除邮件
    deletefun() {
      this.submitloading = true;
      let idarr = [];
      this.inboxlist.forEach((item) => {
        if (item.ischeck == true) {
          idarr.push(item.id);
        }
      });
      let ids = idarr.join(",");
      Emailobject.deleteemail({
        id: ids,
      })
        .then((res) => {
          this.submitloading = false;
          this.deletestatus = false;
          this.$Bus.$emit("get-email-noreadnum");
          if (res.result == true) {
            this.inboxlist = this.inboxlist.filter((val) => {
              return val.ischeck != true;
            });
            this.$message({
              showClose: true,
              // 删除成功
              message: this.$i18n.t("label.ems.delete.success"),
              type: "success",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            // 删除失败
            message: this.$i18n.t("label.ems.delete.false"),
            type: "error",
          });
        });
    },
    //删除邮件弹窗
    deleteDialog() {
      let istrue = this.inboxlist.every((item) => {
        return item.ischeck == false;
      });
      if (istrue == true) {
        //请至少选择一封邮件
        this.$message({
          showClose: true,
          message: this.$i18n.t(
            "vue_label_email_Please_select_at_least_one_email"
          ),
          type: "warning",
        });
      } else {
        this.deletestatus = true;
      }
    },
    //切换邮件列表
    tabEmailList(params) {
      this.inboxlist = [];
      this.scrollnum = 1;
      this.offtime = null;
      this.seachtype = params;
      this.getinboxlist(
        this.EmailType,
        this.seachtype,
        "25",
        this.scrollnum,
        this.offtime,
        ""
      );
    },
    //刷新方法
    refreshFun() {
      this.inboxlist = [];
      this.scrollnum = 1;
      this.offtime = null;
      this.getinboxlist(
        this.EmailType,
        this.seachtype,
        "25",
        this.scrollnum,
        this.offtime,
        ""
      );
    },
    //全选方法
    checkListAll(params) {
      this.inboxlist.forEach((item) => {
        item.ischeck = params;
      });
    },
    //反选方法
    ReverseSelection(params) {
      this.$emit("ReverseSelection", params);
    },
    //改变选中状态
    changecheck(id) {
      this.inboxlist.forEach((item) => {
        if (item.id == id) {
          item.ischeck = !item.ischeck;
        }
      });
      let istrue = this.inboxlist.every((item) => {
        return item.ischeck == true;
      });
      if (istrue == false) {
        this.ReverseSelection(false);
      } else {
        this.ReverseSelection(true);
      }
    },
    //跳转邮件详情页
    async godetail(id, index) {
      if (this.EmailType != "temp") {
        this.$router.push({
          path: `/MailDetail`,
          query: {
            id: id,
            index: index,
            type:this.EmailType,
            inboxlist: this.inboxlist,
          },
        });
      } else {
        let resultData = await Emailobject.emaildetail({
          id: id,
          incoming: "0",
        });
        if (resultData.result) {
          this.$router.push({
            path: `/writeEmail`,
            query: {
              value: resultData.data.detail,
            },
          });
          // 写邮件
          this.$Bus.$emit(
            "change-tab-style",
            this.$i18n.t("label.ems.compose")
          );
        }
      }
    },
  },
};
</script>



<style lang="scss" scoped>
.listBox {
  overflow-y: auto;
  ul {
    li {
      height: 52px;

      display: flex;
      align-items: center;
      text-align: left;
      border-bottom: 2px solid #dcdcdc;
      cursor: pointer;
      .check {
        width: 14px;
        height: 14px;
        display: flex;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        i {
          font-size: 10px;
          color: #fff;
        }
      }
      .relate {
        width: 115px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .isread {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px 0 10px;
      }
    }
    li:first-child {
      border-top: 2px solid #dcdcdc;
    }
  }
}
.nothing {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .nothingicon {
    text-align: center;
    h5 {
      margin-top: 10px;
      font-size: 14px;
      color: #999999;
    }
  }
}
.noDrafts{
  width: 218px;
  height: 133px;
}


</style>