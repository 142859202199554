<template>
  <div>
    <div class="inboxtop">
      <div class="inboxtopleft">
        <div class="checkAll">
          <span
            @click="checkall"
            :style="{
              backgroundColor: isallcheck == true ? '#006DCC' : '#fff',
            }"
          >
            <i class="el-icon-check" v-show="isallcheck == true"></i>
          </span>
          <span>
            <!-- 全选 -->
            {{ $t("label.selectall") }}
          </span>
        </div>
        <div class="refresh" @click="refresh" v-if="EmailType == '1'||EmailType == '5'">
          <i class="el-icon-refresh"></i>
        </div>
        <div v-if="EmailType == '1'||EmailType == '5'">
          <!-- 请选择 -->
          <el-select
            v-model="selectvalue"
            @change="tabemaillist"
            :placeholder="$t('label.select.please')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="delete" @click="deleteemail">
          <el-button>
            <!-- 删除 -->
            {{ $t("label.delete") }}
          </el-button>
        </div>
        <!-- 删除弹窗 -->
        <div>
          <!-- 提示 -->
          <el-dialog
            :title="$t('label.prompt')"
            :visible.sync="deletestatus"
            width="30%"
          >
            <span>
              <!-- 确认要删除吗 -->
              {{ $t("label.temp.del.conf") }}
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="deletestatus = false">
                <!-- 取消 -->
                {{ $t("label.cancel") }}
              </el-button>
              <el-button type="primary" :loading="submitloading">
                <!-- 确定 -->
                {{ $t("label.confirm") }}
              </el-button>
            </span>
          </el-dialog>
        </div>
        <div class="sign" v-if="EmailType == '1'||EmailType == '5'">
          <!-- 标记为 -->
          <el-select
            v-model="signas"
            :placeholder="$t('vue_label_email_mark_as')"
          >
            <el-option
              v-for="item in readstatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click.native="changeisread(item.value)"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="inboxtopright">
        <!-- 请输入内容 -->
        <el-input
          v-model="seachval"
          @keyup.enter.native="seachkeyword"
          :placeholder="$t('label_tabpage_contentz')"
        ></el-input>
        <i class="el-icon-search" @click="seachkeyword"></i>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      isallcheck: false,
      deletestatus: false,
      submitloading: false,
      seachval: "",
      selectvalue: this.$i18n.t("label_partner_workspace_member_all"), //全部
      readstatus: [
        {
          value: "1",
          //已读
          label: this.$i18n.t("label.emailobject.markasread"),
        },
        {
          value: "0",
          // 未读
          label: this.$i18n.t("label.unread"),
        },
      ],
      options: [
        {
          value: "all",
          // 全部
          label: this.$i18n.t("label_partner_workspace_member_all"),
        },
        {
          value: "noread",
          // 未读
          label: this.$i18n.t("label.unread"),
        },
        {
          value: "atta",
          // 含附件
          label: this.$i18n.t("vue_label_email_with_accessories"),
        },
        {
          value: "unclose",
          // 关联未关闭业务机会
          label: this.$i18n.t("label.emailobject.view.connectopp"),
        },
        {
          value: "relate",
          // 来自CRM联系人/潜在客户
          label: this.$i18n.t("label.emailobject.view.fromcrm"),
        },
      ],
    };
  },
  props: {
    //1:收件箱 0:发件箱 temp:草稿箱
    EmailType: {
      type: String,
      default: "",
    },
  },
  watch: {
    seachval(val) {
      if (val == "") {
        this.$emit("seachkeyword", "");
      }
    },
  },
  methods: {
    //搜索邮件列表
    seachkeyword() {
      this.$emit("seachkeyword", this.seachval);
    },
    //更改已读或未读状态
    changeisread(params) {
      this.$emit("changeisread", params);
    },
    //清空标记选项列表
    clearSign() {
      this.signas = "";
    },
    //删除邮件弹窗
    deleteemail() {
      this.$emit("deleteemail");
    },
    //切换邮件列表
    tabemaillist(params) {
      this.$emit("tabemaillist", params);
    },
    //全选
    checkall() {
      this.isallcheck = !this.isallcheck;
      this.$emit("checkall", this.isallcheck);
    },
    //反选
    ReverseSelectionList(params) {
      this.isallcheck = params;
    },
    //刷新列表
    refresh() {
      this.$emit("refresh");
      this.isallcheck = false;
      this.seachval="";
      //刷新成功
      this.$message({
        showClose: true,
        message: this.$i18n.t("vue_label_notice_refreshi_success"),
        type: "success",
      });
    },
  },
};
</script>



<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 30px;
}
::v-deep .el-select .el-input .el-select__caret {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inboxtop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 15px;
  .inboxtopleft {
    display: flex;
    align-items: center;
    .checkAll {
      display: flex;
      align-items: center;
      span:first-child {
        width: 14px;
        height: 14px;
        display: flex;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        margin-right: 5px;
        cursor: pointer;
        i {
          font-size: 10px;
          color: #fff;
        }
      }
    }
    .refresh {
      margin: 0 10px;
      border: 1px solid #dcdcdc;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      cursor: pointer;
    }
    .delete {
      margin: 0 10px;
      ::v-deep .el-button {
        height: 30px;
        display: flex;
        align-items: center;
      }
    }
    .sign {
      width: 80px;
      ::v-deep .el-input__inner {
        padding: 0 0 0 5px !important;
      }
      ::v-deep input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #606266;
      }
    }
  }
  .inboxtopright {
    position: relative;
    display: flex;
    align-items: center;
    i {
      position: absolute;
      right: 6px;
      top: 7px;
      border-left: 0;
      cursor: pointer;
    }
    ::v-deep .el-input__inner {
      padding: 0 20px 0 15px;
    }
  }
}
</style>